import React from 'react';
import * as Styled from './imageWithGreyCardStyle';
import { BLOCKS, INLINES, MARKS } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { useContentfulAssets } from '../../../hooks/useContentfulAssets';
import isExternalUrl, {
  addTrailingSlash,
  isExternalUrlHref,
} from '../../../utils';
import { MainContainer, SpanStyle } from '../../../styles';
import { Col } from 'react-bootstrap';

const ImageWithGreyCard = ({ sectionData }) => {
  const optionsMainStyle = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <Styled.ParaText className="d-none">{children}</Styled.ParaText>
      ),
      [BLOCKS.UL_LIST]: (node, children) => (
        <Styled.UnorderedList className="d-none">
          {children}
        </Styled.UnorderedList>
      ),
      [BLOCKS.LIST_ITEM]: (node, children) => (
        <Styled.ListItemArrow className="d-none">
          {children}
        </Styled.ListItemArrow>
      ),
      [INLINES.ASSET_HYPERLINK]: (node, children) => {
        const asset = useContentfulAssets(node?.data?.target?.sys?.id);
        return (
          <Styled.LinkStyle
            href={
              isExternalUrlHref(asset?.node?.file?.url)
                ? asset?.node?.file?.url
                : addTrailingSlash(
                    process.env.GATSBY_DOMAIN_URL + asset?.node?.file?.url
                  )
            }
            target="_blank"
          >
            {children}
          </Styled.LinkStyle>
        );
      },
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        const asset = useContentfulAssets(node?.data?.target?.sys?.id);
        return asset?.node?.file?.url?.includes('image') ? (
          <Styled.ImageStyle
            src={asset?.node?.file?.url}
            alt={asset?.node?.title}
          />
        ) : (
          <></>
        );
      },
    },
    renderText: (text) =>
      text.split('\n').flatMap((text, i) => [i > 0 && <br key={i} />, text]),
  };
  const optionsMainStyleGrey = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <Styled.ParaText>{children}</Styled.ParaText>
      ),
      [INLINES.HYPERLINK]: (node, children) => (
        <Styled.LinkStyle
          href={
            isExternalUrlHref(node?.data?.uri)
              ? node?.data?.uri
              : addTrailingSlash(
                  process.env.GATSBY_DOMAIN_URL + node?.data?.uri
                )
          }
          target={isExternalUrl(node?.data?.uri) ? '_blank' : '_self'}
        >
          {children}
        </Styled.LinkStyle>
      ),
      [BLOCKS.UL_LIST]: (node, children) => (
        <Styled.UnorderedList>{children}</Styled.UnorderedList>
      ),
      [BLOCKS.LIST_ITEM]: (node, children) => (
        <Styled.ListItemArrow>{children}</Styled.ListItemArrow>
      ),
      [INLINES.ASSET_HYPERLINK]: (node, children) => {
        const asset = useContentfulAssets(node?.data?.target?.sys?.id);
        return (
          <Styled.LinkStyle
            href={
              isExternalUrlHref(asset?.node?.data?.url)
                ? asset?.node?.data?.url
                : addTrailingSlash(
                    process.env.GATSBY_DOMAIN_URL + asset?.node?.data?.url
                  )
            }
            target="_blank"
          >
            {children}
          </Styled.LinkStyle>
        );
      },
    },
    renderText: (text) =>
      text.split('\n').flatMap((text, i) => [i > 0 && <br key={i} />, text]),
  };
  return (
    <Col lg={4}>
      {sectionData?.contentDetails &&
        documentToReactComponents(
          JSON.parse(sectionData?.contentDetails?.raw),
          optionsMainStyle
        )}
      {sectionData?.header?.trim() && (
        <Styled.HeaderH2>{sectionData?.header}</Styled.HeaderH2>
      )}
      {sectionData?.subText?.trim() && (
        <Styled.HeaderH3>{sectionData?.subText}</Styled.HeaderH3>
      )}
      <Styled.BlockQuotes>
        {sectionData?.contentDetails &&
          documentToReactComponents(
            JSON.parse(sectionData?.contentDetails?.raw),
            optionsMainStyleGrey
          )}
      </Styled.BlockQuotes>
    </Col>
  );
};

export default ImageWithGreyCard;
